import React from "react"
import { Link } from "gatsby"
import Sidebar from '../../components/sidebar'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import lgHausys from "../../assets/img/lghausys.jpg"



const lgHausysPage = () => (
  <Layout>
    <SEO title="chargeur sans fil intégré" />
 
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div class="grid grid-cols-1 gap-2 md:col-span-3 md:my-5 mx-3"> {/** first element */}
             <h1 class="text-2xl font-bold mb-8 divider">Chargeur sans fil intégré dans le plan en Solid Surface et iPhone avec coque spéciale</h1>
            <img class="mb-0 mx-auto w-full" src={lgHausys} alt="chargeur sans fil intégré" />
            <p class="text-justify mt-3">
                <span class="font-bold">HI-MACS®</span>, dévoile en avant première, <a href="http://himacs.eu/fr/indoor-products" target="top" class="text-red-700">les couleurs Velvet</a>, une exclusivité de <span class="font-bold">LG Hausys</span>, disponibles le 1re mars prochain.<br />
                Ces 4 couleurs inédites, <span class="font-bold">Mink, Evergreen, Cosmic Blue et Suede,</span> de la gamme Solid, trouvent leur inspiration dans la beauté de la nature et les matières organiques, et s’associent pour des finitions d’ambiance de haute qualité. Velvet ajoute une touche de raffinement authentique et harmonieuse à tous les environnements.<br/>
                <span class="font-bold">EVERGREEN</span><br/>
                Drapez vous dans la profondeur de la teinte Evergreen. Une teinte inspirée du besoin profond de reconnexion avec la nature et notre environnement. Cette couleur se situe entre les tons chauds et froids, dans un environnement qui s’ouvre sur l’extérieur en créant une ambiance sereine. Ce vert organique et sophistiqué se marie idéalement avec le mobilier en bois naturel et les textiles dans des espaces cosmopolites.<br/>
                <span class="font-bold">COSMIC BLUE</span><br/>
                Ajoutez une pointe d’élégance avec la teinte Cosmic Blue. La nuance foncée de ce bleu apporte du volume et ne passe pas inaperçu. Inspiré des textiles de l’industrie de la mode et plus particulièrement du denim, Cosmic Blue est l’alternative parfaite à la palette de couleur unie. Cette teinte exprime l’intensité, quel que soit l’endroit où elle est utilisée. Associez-le à du mobilier métallique ou en bois foncé, ou à des accents de cuivre ou de bronze pour sublimer tout type d’espace.<br/>
                <span class="font-bold">MINK</span><br/>
                Découvrez le luxe soyeux de Mink, une teinte chaleureuse et apaisante qui offrira un fini velouté quel que soit le projet. Ce gris subtil dynamisera de manière singulière votre intérieur et se mariera facilement avec des couleurs neutres tout en apportant un côté chaleureux aux intérieurs blancs. Le fini raffiné de Mink offre une douceur séduisante et une élégance pure, parfaitement adaptées aux espaces sophistiqués, en particulier lorsqu‘il est associé au bois naturel ou à la céramique.<br/>
                <span class="font-bold">SUEDE</span><br/>
                Suede, minimaliste au sens strict, offre une nouvelle couleur neutre qui uniformise l’espace pour n’en révéler que l’essentiel. Inspirée de l’argile brute et des finitions naturelles non traitées, cette couleur est une alternative au blanc pur et offre une sensation d’harmonie et de tranquillité. Cette couleur peut être utilisée avec des touches dorées, par exemple, dans les espaces commerciaux à grande fréquentation.
                La collection Velvet sera disponible en Europe <span class="font-bold">à compter du 1er mars 2017,</span> complétant la large <a href="http://himacs.eu/fr/indoor-products" target="top" class="text-red-700">gamme de couleurs</a>, de textures et d’effets qui font de <span class="font-bold">HI-MACS®</span> <a href="http://himacs.eu/fr/produits" target="top" class="text-red-700">un matériau d’exception</a> qui sublime les créations les plus grandioses.
                <span class="font-bold">LG Hausys</span> dévoile les couleurs Velvet, 4 nouvelles teintes de la gamme Solid de <span class="font-bold">HI-MACS®</span>
            </p>
            <div class="divider mt-3"></div>
            <p class="mt-3 text-lg font-bold">Informations sur le projet</p>
            <p>
              Design <br/>
              LG Hausys HI-MACS®<br/>
              Matériau<br/>
              S118 Mink, S119 Evergreen, S120 Cosmic Blue, S121 Suede<br/>
              Crédits photo<br/>
              LG Hausys HI-MACS®<br/>
              <a href="http://himacs.eu/sites/himacs/files/newsroom/pdf/new_velvet_colours_hi-macs_2017_french.pdf"  target="top" class="text-red-700">TÉLÉCHARGER LA VERSION PDF</a>
            </p>
       </div>
       <div>
         <Sidebar />
       </div>
    </div>
  </Layout>
  )
 
  export default lgHausysPage
